// Enables Selectize on select fields

import { Controller } from "stimulus"
import ScriptLoader from "../utils/scriptloader"

export default class extends Controller {
	static targets = [ "select" ]
	static values = {
			plugins:  { type: Array,   default: [] },
			create:   { type: Boolean, default: false },
			options:  { type: Array,   default: [] },
			settings: { type: Object,  default: {} },
			remote:   String,
			useSiblingOnCreate: { type: Boolean, default: false }
	}

	initialize() {
		this.destroy = this._destroy.bind(this)
		this.selectize = null
	}

	connect() {
		// turns out Selectize removes the required attribute from the original input
		// on initialization, so we need to store this manually and restore it when
		// initializing it again (such as on remoteValueChanged)
		this.required = false
		this.#setup()
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy)
	}

	remoteValueChanged() {
		if (this.selectInput !== undefined) {
			// when the remote value changes we will destroy the selectize element, then we will
			// clear all the options in the select itself because a change in remote value signals
			// a major change in the possible content in select so we clear the options, finally
			// we just setup selectize again
			this.selectInput.selectize.destroy()
			this.selectInput.innerHTML = ""
			if (this.required) { this.selectInput.setAttribute('required', '') }
			this.#setup()

			if (this.selectInput.hasAttribute('deselectize')) {
				this.selectInput.selectize.lock()
			} else {
				this.selectInput.selectize.unlock()
			}
		}
	}

	#setup() {
		this.selectInput = this.hasSelectTarget ? this.selectTarget : this.element

		ScriptLoader.loadScript("selectize", () => {
			let defaultSettings = {
				plugins: this.pluginsValue,
				closeAfterSelect: true,
				createOnBlur: true,
				create: this.createValue
			}

			let settings = Object.assign(defaultSettings, this.settingsValue)

			if (this.selectInput.getAttribute('required')) { this.required =  true }

			if (this.optionsValue.length > 0 ) { settings['options'] = this.optionsValue }

			if (!this.createValue && this.hasRemoteValue) {
				settings["load"] = async (query, callback) => {
					const location = window.location
					const url = new URL(this.remoteValue, `${location.protocol}//${location.host}`)
					url.searchParams.set("term", query)
					const response = await fetch(url.toString(), { method: "GET", headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken } })
					if (response.ok) {
						callback(await response.json())
					} else {
						callback()
					}
				}
			}

			// basically if we allow create option and we want to use the previous sibling to store the value we will
			// override the `create` option with a function that will set the sibling field to the value of input
			if (settings.create && this.useSiblingOnCreateValue) {
				settings.create = (input) => {
					const sibling = this.element.previousSibling
					// we only do something with sibling if we are dealing with an hidden input field
					if (sibling.nodeName === "INPUT" && sibling.type === "hidden") sibling.value = input
					return { text: input, value: input }
				}

				settings.onChange = (input) => {
					const value = parseInt(input)
					const sibling = this.element.previousSibling
					if (!isNaN(value)) {
						const option = this.selectize[0].selectize.options[value]
						if (sibling.nodeName === "INPUT" && sibling.type === "hidden") sibling.value = option.text
					}
				}
			}

			this.selectize = $(this.selectInput).selectize(settings)
			this.selectize.on('change', (event) => {
				this.selectInput.form.dispatchEvent(new Event('change'))
			});
		});
	}

	_destroy() {
		if (this.selectInput.selectize !== undefined) {
			this.selectInput.selectize.destroy()
			this.selectInput.selectedIndex = 0
		}
	}

	get csrfToken() {
		const meta = document.querySelector("meta[name=csrf-token]")
		return meta && meta.content
	}
}
